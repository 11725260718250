import React, { useState, useEffect } from "react";
import Button from "@material-ui/core/Button";
import StripeCheckout from 'react-stripe-checkout';

export default function PayButton(props) {
  console.log('HA',props);
    if(props.is_razor) 
    {
        return(
        <Button
                onClick={props.onPay}
                style={{
                  backgroundColor: "#013F7A",
                  color: "white",
                  width: "8em",
                  marginRight: 10,
                }}
              >
                Pay now ({props.curr_symbol}{props.total})
              </Button>
        )
    }
    else
    {
        return(
            <StripeCheckout
        stripeKey="pk_live_KoJJBJ6NfJyifS06BuIExPsl00HklGasaF"
        token={props.onPay}
        amount={props.total * 100}
        name="UC-SCHOOL REGISTRATION"
        billingAddress
        shippingAddress
        >
        <Button
                style={{
                  backgroundColor: "#013F7A",
                  color: "white",
                  width: "8em",
                  marginRight: 10,
                }}
              >
                Pay now ({props.curr_symbol}{props.total})
              </Button>
        </StripeCheckout>
        )
    }
}
