import { createMuiTheme } from '@material-ui/core/styles';

const theme = createMuiTheme({
  typography: {
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    subtitle1: {
      fontSize: 14,
      fontFamily:"",
      fontFamily: "'Poppins', sans-serif",
    // fontWeight: 500,
    },
    body1: {
      fontWeight: 400,
    },
    button: {
      // fontStyle: 'italic',
    },
  },
 
  palette: {
    // type:"light",
    background: {
         primary: {
          // mainGradient: "linear-gradient(to right, #2AC4A3, #16A5A9)",
         }
        // default: "linear-gradient(to right, #2AC4A3, #16A5A9)"
      },
      // MuiTextField: {
      //   margin: 'dense',
      // },
    },
          // overrides:{
          //   root:{
          //     "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          //       borderColor: "green"
          //     },
          //     "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
          //       borderColor: "red"
          //     },
          //     "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
          //       borderColor: "purple"
          //     },
          //   },
            MuiInputLabel:{
              root:{
                color:"#4F4A67",
                fontSize:"1rem",
                fontWeight:"100",
                "&$focused": {
                  color: "#0FA1A1",
                  fontWeight:"100",
                
                  // fontWeight: "bold"
                 
                },

                  

              }
            },
            
        
          // },
          // label: {
          //   "&$focusedLabel": {
          //     color: "cyan",
          //     fontSize:"3em",
          //     fontWeight:"bold",
          //   },
          //   "&$erroredLabel": {
          //     color: "orange"
          //   }
          // },
          // focusedLabel: {},
          // erroredLabel: {},
          // underline: {
          //   "&$error:after": {
          //     borderBottomColor: "orange"
          //   },
          //   "&:after": {
          //     borderBottom: `2px solid cyan`
          //   }
          // },
          // error: {}
        

      // overrides: {
      //   MuiInputLabel: {
      //     root: {
      //       color: "#0DA1A1",
          
      //     },
      //     '& .MuiInput-underline:after': {
      //       borderBottomColor: 'green',
      //     },
      //     '& .MuiFormHelperText-marginDense':{
      //       margin:"dense",
      //     }
      //   },
      // }
  }
  )
console.log(theme);
  
export default  theme;