import React from "react";
import Register from "./Register";
import { ThemeProvider } from "@material-ui/core/styles";
import theme from "./Theme";
import CssBaseline from "@material-ui/core/CssBaseline";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import Payment from "./Payment"

import PaymentSucess from "./PaymentSucess"



function App() {
  var url = new URL(window.location.href);
  let price = "0.00"
  let mode = "monthly"
  var plan_type = url.searchParams.get('mode')
    if(url.searchParams.get('plan') != null)
    {
      console.log('got here')
      var plan = url.searchParams.get('plan')
      console.log('UC',plan)
      plan = plan.toUpperCase();
      if(plan === 'BASIC')
      {
        console.log('sh');
        if(plan_type == 'yearly')
        {
          price ="2.5"
        }
        else
        {
          price = "0.25"
        }
      }
      else if(plan === 'PROFESSIONAL')
      {
        if(plan_type == 'yearly')
        {
          price ="3.99"
        }
        else
        {
          price = "0.39"
        }
      }
      else if(plan === 'PREMIUM')
      {
        if(plan_type == 'yearly')
        {
          price ="6.49"
        }
        else
        {
          price = "0.65"
        }
      }
      else 
      {
        price = "0.00"
      }

    }
    console.log('Final',price)
    if(url.searchParams.get('mode') != null)
    {
      if(plan_type === 'monthly')
      {
        mode = 'monthly'
      }
      else if(plan_type === 'yearly')
      {
        mode = 'yearly'
      }
      else
      {
        mode = 'monthly' 
      }
    }
  return (
    <div>
     <Router>
      <ThemeProvider theme={theme}>
        {/* <CssBaseline /> */}
    {/* <Payment />  */}
   
{/* <Payment /> */}
    {/* <PaymentSucess /> */}
        

        <Switch>
        
  {/* <Route path="/" exact component={() => <Register price={price}/> } /> */}
          <Route path="/payment_secc" render={(props) => <PaymentSucess/>} />
           <Route path="/payment" render={(props) => <Payment />} />
           <Route path="/" render={(props) => <Register price={price} mode={mode}/>} />

           
        </Switch>
      
      </ThemeProvider>
     </Router>
    </div>
  );
}

export default App;
